@media (min-width: 961px) {
  .footer_box {
    width: 100%;
    padding: 40px 0 30px 0;
    box-sizing: border-box;
    background-image: url(../../assets/img/footer_bg.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #06304E;

    .footer {
      display: flex;
      margin: 0 auto;
      // padding: 80px 0 0 74px;
      width: 1100px;
      justify-content: space-between;

      .logo-box {
        width: 250px;

        img {
          width: 175px;
        }

        .concact-list {
          margin-top: 115px;
          font-size: 14px;
          font-family: NunitoSans-SemiBold;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 12px;

          .concact-list_box {
            display: flex;
            align-items: center;
            margin-top: 16px;

            .concact-list_box_item_twitter {
              width: 21px;
              height: 21px;
              margin-right: 21px;
              margin-top: 1px;
              cursor: pointer;
              background-image: url(../../assets/img/twitter.png);
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }

            .concact-list_box_item_twitter:hover {
              width: 22px;
              height: 22px;
              margin-right: 20px;
              margin-top: 0px;
            }

            .concact-list_box_item_medium {
              width: 21px;
              height: 21px;
              margin-right: 21px;
              margin-top: 1px;
              cursor: pointer;
              background-image: url(../../assets/img/medium.png);
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }

            .concact-list_box_item_medium:hover {
              width: 22px;
              height: 22px;
              margin-right: 20px;
              margin-top: 0px;
            }

            .concact-list_box_item_discord {
              width: 21px;
              height: 21px;
              margin-right: 21px;
              margin-top: 1px;
              cursor: pointer;
              background-image: url(../../assets/img/discord.png);
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }

            .concact-list_box_item_discord:hover {
              width: 22px;
              height: 22px;
              margin-right: 20px;
              margin-top: 0px;
            }

            .concact-list_box_item_telegram {
              width: 21px;
              height: 21px;
              margin-right: 21px;
              margin-top: 1px;
              cursor: pointer;
              background-image: url(../../assets/img/telegram.png);
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }

            .concact-list_box_item_telegram:hover {
              width: 22px;
              height: 22px;
              margin-right: 20px;
              margin-top: 0px;
            }
          }
        }

        .copyright {
          margin-top: 30px;
          color: #777C86;
          font-size: 12px;
          font-family: NunitoSans-SemiBold;
          font-weight: 600;
          color: #5D85A1;
          line-height: 12px;
        }
      }

      .link-list {
        display: flex;
        width: calc(100% - 200px - 175px);

        .link-item {
          padding: 25px 0 30px 0;
          flex: 1;

          p {
            display: flex;
            align-items: center;

            .certik_img {
              width: 24px;
              margin: 0 19px 0 9px;
            }

            .trailbits_img {
              width: 42px;
              margin: 0 10px 0 0px;
            }

            .abdk_img {
              width: 38px;
              margin: 0 12px 0 2px;
            }
          }

          p:first-child {
            font-size: 16px;
            font-family: NunitoSans-SemiBold;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 14px;
          }

          p:not(:first-child) {
            margin-top: 24px;
            font-size: 14px;
            font-family: NunitoSans-Light;
            font-weight: 400;
            color: #7D9CB2;
            line-height: 14px;
            cursor: pointer;
          }

          p:not(:first-child):hover {
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }

  }
}

@media (max-width: 960px) {
  .footer_box {
    width: 100%;
    padding: 30px 0 0;
    box-sizing: border-box;
    background-image: url(../../assets/img/footer_bg_m.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: #06304E;
    background-position: center center;
    position: relative;

    .footer_dot {
      position: absolute;
      top: 0;
      right: 19px;
      width: 89px;
      height: 88px;
    }

    .concact-list {
      margin-top: 25px;
      font-size: 16px;
      font-family: NunitoSans-SemiBold;
      font-weight: 600;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 16px;

      .concact-list_box {
        display: flex;
        align-items: center;
        margin-top: 0px;

        .concact-list_box_item_twitter {
          width: 21px;
          height: 21px;
          margin-right: 21px;
          margin-top: 1px;
          cursor: pointer;
          background-image: url(../../assets/img/twitter.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .concact-list_box_item_twitter:active {
          width: 22px;
          height: 22px;
          margin-right: 20px;
          margin-top: 0px;
        }

        .concact-list_box_item_medium {
          width: 21px;
          height: 21px;
          margin-right: 21px;
          margin-top: 1px;
          cursor: pointer;
          background-image: url(../../assets/img/medium.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .concact-list_box_item_medium:active {
          width: 22px;
          height: 22px;
          margin-right: 20px;
          margin-top: 0px;
        }

        .concact-list_box_item_discord {
          width: 21px;
          height: 21px;
          margin-right: 21px;
          margin-top: 1px;
          cursor: pointer;
          background-image: url(../../assets/img/discord.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .concact-list_box_item_discord:active {
          width: 22px;
          height: 22px;
          margin-right: 20px;
          margin-top: 0px;
        }

        .concact-list_box_item_telegram {
          width: 21px;
          height: 21px;
          margin-right: 21px;
          margin-top: 1px;
          cursor: pointer;
          background-image: url(../../assets/img/telegram.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .concact-list_box_item_telegram:active {
          width: 22px;
          height: 22px;
          margin-right: 20px;
          margin-top: 0px;
        }
      }
    }

    .copyright {
      margin-top: 25px;
      font-size: 14px;
      color: #777C86;
      font-size: 12px;
      font-family: NunitoSans;
      font-weight: 600;
      color: #5D85A1;
      line-height: 12px;
    }

    .footer {
      display: flex;
      margin: 0 auto;
      padding: 0px 0 24px 0;
      width: 100%;
      justify-content: flex-start;
      flex-direction: column;
      width: calc(100% - 40px);

      .logo-box {
        width: 200px;

        img {
          width: 97px;
        }



      }

      .link-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .footer_head_down {
          width: 11px;
          height: 11px;
          margin-right: 10px;
          background-image: url(../../assets/img/footer_head_right.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .footer_head_down_act {
          background-image: url(../../assets/img/footer_head_down.png);
        }

        .link-item {
          width: 100%;
          flex: unset;
          margin-top: 20px;

          p {
            display: flex;
            align-items: center;

            .certik_img {
              width: 24px;
              margin: 0 19px 0 9px;
            }

            .trailbits_img {
              width: 42px;
              margin: 0 10px 0 0px;
            }

            .abdk_img {
              width: 38px;
              margin: 0 12px 0 2px;
            }
          }


          p:first-child {
            font-size: 16px;
            font-family: NunitoSans-ExtraBold;
            font-weight: 800;
            color: #FFFFFF;
            line-height: 16px;
          }

          p:not(:first-child) {
            font-family: NunitoSans-Light;
            font-weight: 300;
            padding-left: 20px;
            margin-top: 8px;
            color: #777C86;
            cursor: pointer;
          }

          p:not(:first-child):active {
            color: #FFFFFF;
          }

          p:not(:first-child):hover {
            // color: #005BE6;
          }
        }

        .link-item-m,
        .link-item-m2 {
          width: 100%;
        }

        .link-item-m2 {
          display: flex;
          flex-wrap: wrap;
        }

        .link-item-m-p-tit {
          width: 100%;
        }

        .link-item-m-p {
          width: 50%;
        }
      }
    }

    .footer_bot {
      width: 100%;
      padding: 20px;
      margin-top: 80px;
      background: #16202F;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;

      font-weight: bold;
      color: #FFFFFF;
      line-height: 16px;
      text-align: center;
    }
  }
}