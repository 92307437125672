@import "../../styles/mixin.scss";
@import "../../styles/animate.scss";

.select_lang {
  font-size: 16px;

  font-weight: bold;
  color: #FFFFFF;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  // box-sizing: border-box;

  .lang_text {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 55px;
    height: 56px;
    background: #FFFFFF;
    border-radius: 18px;
    margin-left: 10px;
    // padding: 0px 13px;
    // width: 100%;
    // height: 100%;
    // overflow: hidden;
    // text-align: center;
    // text-overflow: ellipsis;
    // white-space: nowrap;

    span {
      flex: 1;
    }

    .select_lang_icon {
      width: 13px;
      height: 7px;
      margin-left: 8px;
    }

    .earth_lang {
      width: 17px;
      height: 16px;
    }
  }

  // .lang_text:hover {
  //   transform: scale(1.1);
  // }

  .select_lang_mask {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    z-index: 110;
  }

  .select_lang_menu {
    z-index: 111;
    position: absolute;
    top: 60px;
    left: -20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 18px 0px #EFEFEF;
    border-radius: 24px;
    
    .select_lang_menu_item {
      padding: 2px;
      width: 117px;
      text-align: center;
      font-size: 16px;
      font-family: NunitoSans;
      font-weight: normal;
      color: #052F4D;
      line-height: 16px;
      padding: 10px 0;
      // box-sizing: border-box;
    }
    .selected_lang_menu_item {
      color: #4183FA
    }

    .select_lang_menu_item:hover {
      background: rgba(0, 0, 0, .05);
    }

    .select_lang_menu_item+.select_lang_menu_item {
      // border-top: 1px solid #19212E;
    }

    .select_lang_menu_item_action {
      // background-color: #EAE8EF;
      color: #4183FA;
    }
  }

  .active {
    animation: rotate .4s forwards;
  }
}

.select_lang_move {
  color: #16202F;

  .lang_text {
    background-color: #EBECF0;
  }
}

@media (max-width: 960px) {
  .select_lang {
    // width: 80px;
    // // height: 46px;
    // margin-left: 50px;

    width: 155px;
    height: 50px;

    .lang_text {


      width: 155px;
      height: 50px;
      border: 2px solid #4183FA;
      background: #fff;
      border-radius: 14px;
      font-size: 16px;
      font-family: NunitoSans-Bold;
      font-weight: 800;
      color: #052F4D;
      line-height: 50px;
      text-align: center;
    }

    .select_lang_menu {

      
      top: unset;
      bottom: 70px;
      left: 5px;

      .select_lang_menu_item {
        width: 145px;
        padding: 8px 2px;
      }
    }
  }

  .select_lang_move {
    color: #16202F;
  }
}