.App {
  background-color: #EBECF0;
  font-family: NunitoSans;

  .content {
    padding-top: 80px;
  }

  .message-cus {
    padding: 2px 3px;
  }
}

@media (max-width: 960px) {
  .App {
    background-color: #EBECF0;

    .content {
      padding-top: 70px;
    }

    .message-cus {
      padding: 2px 3px;
    }
  }

}