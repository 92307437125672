.toggle {
  max-height: 0;
  overflow: hidden;
  animation: toggle .8s forwards;
}
@keyframes toggle
{
  from {max-height: 0}
  to {max-height: 100vh;}
}

.scale {
  animation: scale .4s forwards;
}
@keyframes scale
{
  from {transform: scale(1)}
  to {transform: scale(1.03)}
}

@keyframes scale1
{
  from {transform: scale(1)}
  to {transform: scale(1.1)}
}

@keyframes opacity
{
  from {opacity: 1}
  to {opacity: 0.8}
}

@keyframes rotate
{
  from {transform: rotate(0)}
  to {transform: rotate(180deg)}
}

