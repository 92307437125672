@import "../../font/index.scss";

@media (min-width: 961px) {
  .nav_box {
    position: absolute;
    // position: fixed;
    top: -80px;
    background: transparent;
    width: 100%;
    height: 80px;
    color: #0C101C;
    z-index: 999;

    .nav_more_box {
      width: 100vw;
      height: calc(100vh - 80px);
      position: fixed;
      top: -120vh;
      // top: 80px;
      left: 0;
      z-index: 1999;
      transition: top 0.3s;
      // display: none;


      .nav_more_content {
        width: 100vw;
        padding-bottom: 40px;
        background-color: #fff;
        box-shadow: 0px 157px 191px 0px rgba(5, 47, 77, 0.28);

        .nav_more_content_min {
          width: 1100px;
          margin: 0 auto;

          .nav_more_content_min_c {
            margin-left: 757px;

            .nav_more_content_min_c_item {
              padding-top: 30px;
              font-size: 18px;
              cursor: pointer;
              font-family: NunitoSans;
              font-weight: 900;
              color: #052F4D;
              line-height: 18px;

              span {
                font-weight: 400;
              }
            }

            .nav_more_content_min_c_item_act {
              // color: #4183FA;
            }
          }


        }
      }

    }


    .nav_more_box_block {
      top: 80px;
      // display: block;
    }

    .nav-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      width: 1100px;
      height: 100%;

      .nav-content-left,
      .nav-content-right {
        display: flex;
        align-items: center;
      }

      .nav_line {
        width: 1px;
        height: 65px;
        margin: 0 40px;
        background-color: #38485F;
      }

      .more {
        width: 54px;
        cursor: pointer;
      }

      .logo {
        width: 143px;
        height: 51px;
        cursor: pointer;
      }

      .go_to_app {
        width: 164px;
        height: 56px;
        background: #4183FA;
        border-radius: 18px;
        font-size: 14px;
        font-family: NunitoSans;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 38px;
      }

      .go_to_app:hover {
        background: #2b73f9
      }

      .nav_link_box {
        height: 54px;
        background: #FFFFFF;
        border-radius: 18px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        padding: 0 30px 0 30px;
        // overflow: hidden;

        .nav_link_item {
          line-height: 54px;
          padding: 0 15px;
          font-size: 14px;
          font-family: NunitoSans;
          font-weight: 600;
          color: #052F4D;
          line-height: 49px;
          cursor: pointer;
          position: relative;

          .nav_link_on {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -2px;
            width: 70px;
            height: 5px;
            opacity: 0;
          }

          .nav_link_item_c_box {
            display: none;
            position: absolute;
            top: 40px;
            left: 50%;
            transform: translateX(-50%);

            .nav_link_item_c {

              width: 280px;
              padding: 10px 30px;
              background: #FFFFFF;
              box-shadow: 0px 75px 250px 0px rgba(5, 47, 77, 0.28);
              border-radius: 24px;
              cursor: pointer;
            }

            .nav_link_item_c_item_blb {
              width: 100%;
              height: 20px;
            }

            .nav_link_item_c_item {
              font-size: 14px;
              font-family: NunitoSans;
              font-weight: 900;
              color: #052F4D;
              line-height: 30px;

            }

            .nav_link_item_c_item:hover {
              color: #2b73f9;
            }
          }

        }

        .nav_link_item:hover {

          .nav_link_item_c_box {
            display: block;
          }

          .nav_link_on {
            opacity: 1;
          }
        }
      }
    }
  }

  @keyframes mymove {
    from {
      top: -80px;
    }

    to {
      top: 0px;
    }
  }

  .nav_box_move {
    background-color: #fff;
    top: 0;
    position: fixed;
    // animation: mymove 0.3s 1;
    display: block;

    .go_to_app {
      // background-color: #005BE6 !important;
      color: #fff !important;
    }

    .nav_link_box {
      background-color: #EBECF0 !important;
    }
  }

  .nav_box_move_top {
    top: 0;
  }
}

@media (max-width: 960px) {
  .nav_box {
    // position: sticky;
    position: absolute;
    top: -70px;
    background: transparent;
    width: 100%;
    height: 60px;
    color: #0C101C;
    z-index: 999;

    .nav_more_box {
      width: 100vw;
      height: 100%;
      position: fixed;
      top: -100%;
      left: 0;
      z-index: 1999;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: top 0.3s;
      background-color: #fff;
      padding-bottom: 30px;

      .nav_more_box_header {
        height: 60px;
        width: calc(100% - 20px);
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header_logo {
          width: 97px;
          height: 34px;
        }

        .header_close {
          width: 22px;
          height: 22px;
        }
      }

      .nav_more_content_min {
        width: calc(100% - 40px);
        margin: 0 auto;

        .nav_more_content_min_head {
          margin-top: 40px;
          font-size: 25px;
          // NunitoSans
          font-family: NunitoSans-Bold;
          font-weight: 800;
          color: #052F4D;
          line-height: 25px;
          display: flex;
          align-items: center;

          .nav_more_content_min_head_down {
            width: 11px;
            height: 11px;
            margin-right: 12px;
            background-image: url(../../assets/img/nav_right_m.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          .nav_more_content_min_head_down_act {
            background-image: url(../../assets/img/nav_down_m.png);
          }
        }

        .nav_more_content_min_item {
          margin-top: 24px;
          font-size: 24px;
          font-family: NunitoSans-Regular;
          font-weight: 400;
          color: #7D9CB2;
          line-height: 18px;
          padding-left: 23px;
        }

        .nav_more_content_min_item:active {
          color: #4183FA;
        }
      }
    }

    .nav_more_box_block {
      top: 0;
    }

    .nav-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      width: 100%;
      height: 100%;

      .nav-content-left,
      .nav-content-right {
        display: flex;
        align-items: center;
      }


      .nav-content-left {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
      }

      .nav-content-right {
        display: none;
      }

      .nav_line {
        width: 1px;
        height: 65px;
        margin: 0 40px;
        background-color: #38485F;
        display: none;
      }

      .nav-content-left_m {
        display: flex;
        align-items: center;
      }

      .launch_app_m {
        margin-right: 10px;
        width: 107px;
        height: 40px;
        text-align: center;
        background: #4183FA;
        border-radius: 14px;
        font-size: 14px;
        font-family: NunitoSans;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 40px;
      }

      .launch_app_m:active {
        background: #3277f6
      }

      .more {
        width: 35px;
        height: 18px;
        cursor: pointer;
      }

      .more:active {}

      .logo {
        width: 97px;
        height: 34px;
        cursor: pointer;
      }

      .go_to_app {
        width: 122px;
        height: 48px;
        background: #ECEFF3;
        border-radius: 4px;
        font-size: 16px;

        font-weight: bold;
        color: #16202F;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 38px;
        display: none;
      }

      .a_img {
        width: 20px;
        margin-left: 40px;
        cursor: pointer;
      }
    }
  }


  .nav_more_box_btn {
    width: calc(100% - 40px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav_more_box_btn1 {
      width: 155px;
      height: 50px;
      background: #4183FA;
      border-radius: 14px;
      font-size: 16px;
      font-family: NunitoSans-Bold;
      font-weight: 800;
      color: #FFFFFF;
      line-height: 50px;
      text-align: center;
    }

    .nav_more_box_btn1:active {
      background: #367afa;
    }
  }

  @keyframes mymove {
    from {
      top: -70px;
    }

    to {
      top: 0px;
    }
  }

  .nav_box_move {
    background-color: #fff;
    top: 0;
    position: fixed;
    animation: mymove 0.3s 1;

    .go_to_app {
      background-color: #005BE6 !important;
      color: #fff !important;
    }
  }

  .nav_box_move_top {
    top: 0;
  }

}